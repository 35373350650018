import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import styles from './Item.module.sass';
import { useParams, useHistory, useLocation } from 'react-router-dom';
//import parse from 'html-react-parser';
//import { details, increment, setEuroValue } from '../../redux/counterSlice';
import { setEuroValue } from '../../redux/counterSlice';
import { axiosInstance } from '../../utils/API';
import Swal from 'sweetalert2';
import Web3 from 'web3';
import { toHex, toBN } from 'web3-utils';
import ReactPlayer from 'react-player';
import { useSelector, useDispatch } from 'react-redux';
import headerStyles from '../../components/Header/Header.module.sass';
//import Counter from '../counter/Counter';
import {
      contract_auction_abi
    , PopUpAlert
    , PopUpWarning
    , PopUpWarningThen
    , connector
    , getBalanceToken
    , getProvider
} from '../../controller/utils';
//import Lightbox from './Lightbox';
import Loader from './Loader';
import { checkNetwork } from './../../controller/utils';
import ResellModal from '../MyAssets/ResellModal';
//import { CircularProgress } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import { decryptData } from '../../utils/decrypt';
import { countCart } from '../../redux/cartSlice';
import {useCreateStripeCheckout} from './apis/useCreateStripeCheckout'
import {
    displayPrice
    //, displayCryptoPrice
} from '../../utils/displayPrice';
import { NAMES_CONSTANTS } from '../../constants';
//import Button from '@material-ui/core/Button';
//import Button from "@mui/material/Button";
//import ClickAwayListener from '@material-ui/core/ClickAwayListener';
//import { ClickAwayListener } from '@mui/base/ClickAwayListener';
//import Grow from '@material-ui/core/Grow';
//import Paper from '@material-ui/core/Paper';
//import Paper from "@mui/material/Paper";
//import Popper from '@material-ui/core/Popper';
//import Popper from '@mui/material/Popper';
//import MenuItem from '@material-ui/core/MenuItem';
//import MenuItem from '@mui/material/MenuItem';
//import MenuList from '@material-ui/core/MenuList';
//import MenuList from '@mui/material/MenuList';
//import Typography from '@material-ui/core/Typography';
//import Typography from "@mui/material/Typography";
//import { makeStyles } from '@material-ui/core/styles';
import { makeStyles } from "@mui/styles";
//import Icon from '@material-ui/core/Icon';
//import Icon from '@mui/material/Icon';
//import WalletStripeIcon from '../../assets/images/icons/wallet-stripe.svg';
//import WalletCryptoIcon from '../../assets/images/icons/wallet-crypto.svg';
//import DropdownIcon from '../../assets/images/icons/dropDown.svg';
import { numberWithCommas } from '../../utils/formatPricingNumber';

const useStyles = makeStyles((theme) => ({
  popper: {
    width: 220,
    marginTop: 10,
    borderRadius: 24,
    padding: '20px 16px',
    border: '1px solid #E6E8EC',
  },
  buyItem: {
    borderRadius: 200,
    border: '2px solid #E6E8EC',
    padding: '10px 20px',
    "&:hover": {
      backgroundColor: '#A28A00',
      color: '#fff',
    },
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 600,
    fontSize: 14
  },
  typo: {
    textAlign: 'center',
    fontWeight: 600,
    marginBottom: 10
  },
  iconRoot: {
    width: 'inherit',
    height: 'inherit',
    marginRight: 10
  },
  iconRoot2: {
    width: 'inherit',
    height: 'inherit',
    position: 'absolute',
    right: 15
  }
}));

const acceptAll = '*/*';

let navLinks;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Item = props => {
    console.info('Item()');

    //let currency = 'MATIC';

    console.debug('Item: props.userDetails = ', props.userDetails);
    console.debug('Item: localStorage.getItem('+NAMES_CONSTANTS.USER_WALLET_DETAILS+') = ', localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS));

    let walletUserDetailsObject;
    let tokenBal;
    let profilePicUrl;
    const [walletUserDetails, setWalletUserDetails] = useState(
        localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS)
    );

    if (walletUserDetails) {
        console.debug('walletUserDetails', walletUserDetails);
        walletUserDetailsObject = JSON.parse(walletUserDetails);
        console.debug('walletUserDetailsObject', walletUserDetailsObject);
        tokenBal = walletUserDetailsObject.balance;
        console.debug('tokenBal', tokenBal);
        profilePicUrl = walletUserDetailsObject.profilePicUrl;
        console.debug('profilePicUrl', profilePicUrl);
    }

    const userWalletDetails = useSelector(store => store.user.user);
    console.debug('userWalletDetails', userWalletDetails);

    if (props?.mode !== 'view') {
        navLinks = ['Info', 'Description', 'Creator'];
    } else {
        navLinks = ['Info', 'Description', 'Creator', 'Purchase Info'];
    }

    //
    //
    //const testValue = useSelector(function(state) { alert(state); return state.counter.euroValue});
    //const testValue = useSelector((state) => {

    //const euroValue = useSelector(state => state.counter.euroValue) || 0;
    const euroValue = useSelector(state => state.counter.euroValue);
    //const euroValue = MATIC_USD_EXCHANGE_RATE; // Temporary

    //const secretKey = useSelector(state => state?.keyVal?.keyData);
    //let verifyUser = useSelector(state => state?.counter?.userVerifyStatus);
    const dispatch = useDispatch();

    //let provider, account, balance;
    let provider;
    let Web3Provider = new Web3(Web3.givenProvider);

    if (!connector.connected) {
        // create new session
        connector.createSession();
    }

    const history = useHistory();
    const location = useLocation();

    // User/Product stuff
    const { id } = useParams();
    //const query = useQuery();
    const sessionId = new URLSearchParams(window.location.search).get("session_id");
    const userId = JSON.parse(
        localStorage.getItem(NAMES_CONSTANTS.USER_WALLET_DETAILS)
    )?.userId;
    const [userDetails, setUserDetails] = useState({});

    // Product stuff
    const [productDetails, setProductDetails] = useState(null);
    const [productInfo, setProductInfo] = useState(null);
    const [orderId, setOrderId] = useState(null);
    const [resalePopup, setResalePopup] = useState(false);
    //const [coinPaymentReq, setCoinPaymentReq] = useState(null);
    const walletStatus = useSelector(state => state.counter.value);
    //const [activeIndex, setActiveIndex] = useState(0);
    const [categories, setCategories] = useState([]);
    //const [subCategories, setSubCategories] = useState([]);
    //const [subSubCategories, setSubSubCategories] = useState([]);
    const [fractionQuantity, setFractionQuantity] = useState(1);
    const [errorFraction, setErrorFraction] = useState(false);
    const [attemptAddToCard, setAttemptAddToCard] = useState(0);
    //const [address, setAddress] = React.useState("");
    //const [error, setError] = useState(null)

    // UI stuff
    const [open, setOpen] = useState(false);
    const [paymentMethodOpen, setPaymentMethodOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    //const [currentNetwork, setCurrentNetwork] = useState(localStorage.getItem('network'));
    //const classes = useStyles();

    const [active, setActive] = useState(false);
    const [loaderActive, setLoaderActive] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true); //show loader initially only
    const [selectedTab, setSelectedTab] = useState(); //show loader initially only

    // Auction stuff
    const [isUpcomingBid, setIsUpcomingBid] = useState(false);
    const [productPreviews, setProductPreviews] = useState([]);
    const [shortLimit, setShortLimit] = useState(450);
    //const [cLimit, setCLimit] = useState(500);
    //const [limit, setLimit] = useState(500);
    const [isAuction, setIsAuction] = useState(false);
    const exchangeRate = useSelector(state  => state.counter.euroValue);

    // Stripe actions
    const {
        mutate: createStripeCheckout
        //, isLoading: isCheckoutLoading
    } = useCreateStripeCheckout();

    useEffect(() => {

        //getPriceFeed(); // Temporarily removed, using dollars instead

        getProfileDetails(userId);
        getProductDetails(id);

        // Do we need this 30000 millisecond refresh?
        /*
        const detailInterval = setInterval(() => {
            getProductDetails(id);
        }, 30000);
        */

        document.getElementsByTagName('body')[0].className = '';

        //return () => clearInterval(detailInterval);

    }, [initialLoad]); // The reason for having initialLoad as dependecy is
                       // because, with empty dependecy, the setinterval will
                       // take only the initial values of the state hence the
                       // loader inside the getProductuserDetails gets called everytime.

    useEffect(() => {
        if(sessionId) {
            PopUpAlert('Success', 'Order Created Successfully', 'success');
        }

        if (navLinks && Array.isArray(navLinks)) {
            setSelectedTab(navLinks[1]);
        }

        return () => {
            if(attemptAddToCard === 0) {
                sendViewProductEvent({
                    addToCard: false,
                    initiateCheckout: false,
                })
            }
        }

    }, []);

    /*
    const checkIfUserNotOwnAsset = () => {

      if (productDetails?.isFractional && productInfo?.isResell) {
        return false;
      } 
        if (`${userWalletDetails.userId}` != productDetails?.purchasedBy) {
          return true;
        } else {
          return false;
        
      }
    };
    */

    /*
    const handleTogglePaymentMethod = () => {
      setPaymentMethodOpen((prevOpen) => !prevOpen);
    };
    */

    const handleClose = (event) => {

        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setPaymentMethodOpen(false);
    };

    /*
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setPaymentMethodOpen(false);
      }
    }
    */

    const prevOpen = React.useRef(paymentMethodOpen);
    React.useEffect(() => {
        if (prevOpen.current === true && paymentMethodOpen === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = paymentMethodOpen;
    }, [paymentMethodOpen]);

    const getPriceFeed = async () => {
        const url = process.env.REACT_APP_ADMIN_URL;
        await axiosInstance
            .get(url + '/admin/exchange/rate')
            //.then(res => dispatch(setEuroValue(res.data?.result?.exchangeRate)));
            .then(res => setExchangeRate(res.data?.result?.exchangeRate));
    };

    const setExchangeRate = async (exchangeRate) => {
        dispatch(setEuroValue(exchangeRate));
    }

    /*
    const createOrder = async () => {
      let bodyObject = {
        ethPrice: productDetails?.regularPrice,
        //, from: account[0]
        from: walletUserDetailsObject.accounts[0],
        quantity: 1,
        salePrice: productDetails?.regularPrice,
        saleType: productDetails?.saleType,
        status: 'pending',
        to: toAddress,
        paymentType: 'sardine'
        // , cardDetails: {
        //     metadata: {
        //           email: fields?.email
        //         , sessionId: 'DE6FA86F60BB47B379307F851E238617'
        //         , ipAddress: '189.169.121.59'
        //         // "phoneNumber": ""
        //     }
        //     , amount: {
        //           amount: productDetails?.regularPrice
        //         //, currency: 'USD'
        //         , currency: 'MATIC'
        //     }
        //     //, source: {
        //     //    id: cardInfo.circleCardId
        //     //    , type: 'card'
        //     //}
        //     //, autoCapture: true
        //     , idempotencyKey: ''
        //     //, keyId: keyId
        //     , verification: 'cvv'
        //     , description: 'Payment'
        //     //, encryptedData: window.btoa(encryptedData)
        //     //, "channel": "ba943ff1-ca16-49b2-ba55-1057e70ca5c7"
        //     }
      };
      let fetchConfig = {
        method: 'POST',
        headers: {
          Accept: acceptAll,
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
        },
        body: JSON.stringify(bodyObject)
      };

      const res = await axiosInstance.post(
        `asset/checkout/${id}`,
        bodyObject,
        fetchConfig
      );
      if (res.status === 200 || res.status === '200') {
        console.info('Successfully, Your request have been sent.');
      } else {
        console.error(`Somthing went wrong at POST asset/checkout/${id}`, res);
      }
      PopUpAlert('Success', 'Order Created Successfully', 'success');
      return;
    };
    */

    const createOrderWithStripe = async (event) => {

        if(event) {
          handleClose(event)
        }
        if (!walletUserDetailsObject) {
          PopUpAlert('Alert', 'Please log in or connect your wallet to buy.', 'error');
          return;
        }

        const price =  Number(displayPrice(productInfo, exchangeRate));

        const userAddress =
        productDetails.purchasedBy === productInfo?.userId
          ? productInfo?.user?.walletId
          : '';

        const metadata = {
          id,
          quantity: productDetails.quantity,
          currency: 'eur',
          name: productDetails.name,
          description: productDetails.description,
          unit_amount: price
        }

      const asset = {
        mediaPreviewUrl: productInfo.asset.mediaPreviewUrl,
        name: productInfo.asset.name,
        id: productInfo.asset.id,
        assetId: productInfo.assetId
      }


      let body = {
        salePrice: productInfo?.isResell || price,
        exchangeRate,
        saleType: productInfo?.saleType,
        status: 'pending',
        quantity: productDetails?.isFractional ? parseInt(fractionQuantity) : 1,
        from: walletUserDetailsObject.accounts[0],
        to: productInfo?.isResell
          ? userAddress
          : process.env.REACT_APP_ADMIN_ADDRESS,
        ethPrice: productDetails?.isFractional
          ? productInfo?.isResell
            ? productInfo?.resalePrice
            : productDetails?.regularPrice
          : productInfo?.isResell
          ? productInfo?.resalePrice
          : productDetails?.regularPrice,
        paymentType: 'stripe',
        isFractional: productDetails?.isFractional ? true : false,
        asset,
        metadata
      };

    sendViewProductEvent({
        addToCard: false,
        initiateCheckout: false,
        initiatePurchase: true,
    });

      await createStripeCheckout({...body, id: productInfo?.id }, {
        onSuccess: (res) => {
          const url = res.data.result.url;
          window.location.replace(url);
        },
        onError: (err) => {
          setActive(false);
          //PopUpAlert('Alert', err.response?.data?.message, 'error');
          PopUpAlert(
              'Alert'
              , err.response?.data?.message + ' ' + err.response?.data?.error
              , 'error'
          );
          getProductDetails();
        }
      })
    }

    /*
    const createOrderWithWallet = async (event) => {
      if(event) {
        handleClose(event)
      }
      if(!userWalletDetails.network) {
        return props.chooseWalletType()
      }

      const type = userWalletDetails.network;
      const web3provider = await getProvider(type);
      let web3 = new Web3(web3provider);
      const userAddress =
        productDetails.purchasedBy === productInfo?.userId
          ? productInfo?.user?.walletId
          : '';

      let transactionObject = {
        from: userWalletDetails.accounts[0],
        to: productInfo?.isResell
          ? userAddress
          : process.env.REACT_APP_ADMIN_ADDRESS,
        value: web3.utils.toWei(`${productDetails?.regularPrice?.toFixed(4)}`)
      };
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
        }
      };

      const asset = {
        mediaPreviewUrl: productInfo.asset.mediaPreviewUrl,
        name: productInfo.asset.name,
        id: productInfo.asset.id,
        assetId: productInfo.assetId
      };

      let body = {
        salePrice: productInfo?.isResell
          ? productInfo?.resalePrice
          : productDetails?.regularPrice,
        saleType: productInfo?.saleType,
        status: 'pending',
        quantity: productDetails?.isFractional ? parseInt(fractionQuantity) : 1,
        from: transactionObject.from,
        to: transactionObject.to,
        ethPrice: productDetails?.isFractional
          ? productInfo?.isResell
            ? productInfo?.resalePrice
            : productDetails?.regularPrice
          : productInfo?.isResell
          ? productInfo?.resalePrice
          : productDetails?.regularPrice,
        paymentType: type,
        isFractional: productDetails?.isFractional ? true : false,
        asset
      };
      try {
        apicall();
        async function apicall() {
          await axiosInstance
            .post(`/asset/checkout/${productInfo?.id}`, body, config)
            .then(async res => {
              const { data } = res;
              const { result } = data;




              await handleBuyNFTWithMetaMask(
                result.ethPrice,
                result.assetId,
                result.quantity,
                1, // 1: 'fixedPrice' 2: 'auction'
                result.id, // orderId
                result.sellerAddress
              );
            })
            .catch(err => {
              setActive(false);
              PopUpAlert('Alert', err.response?.data?.message, 'error');
              getProductDetails();
              return;
            });
        }
      } catch (error) {
        alert('Please connect your wallet.');
      }
    };
    */

    /*
    const checkbalance = balance => {
      return balance >= productDetails.salePrice ? true : false;
    };
    */

    const getProfileDetails = async () => {


      /*
      if (!status) {
          // PopUpAlert('Disconnected', 'Connect to view your account userDetails', 'error');
          history.push('/search/all');
          return;
      }
      */

      const config = getConfig();
      //if (!config) {
      //    history.push('/');
      //    return;
      //}
      if (config) {
        await axiosInstance
          .get(`user/get-profile`, config)
          .then(res => {
            /*
            const {
              firstName,
              lastName,
              email,
              mobileNumber,
              userName,
              profilePicUrl,
              status,
              addresses
            } = res.data?.result;
            */
            setUserDetails(res.data?.result);
          })
          .catch(err => {
            setUserDetails(null);
          });
      }
    };

    const handleCircelPayment = () => {
      const access = localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN);
      if (access) {
        history.push({
          pathname: `/item/${id}/checkout`,
          state: { productDetails, fromPrevious: true }
        });
      } else {
        setOpen(false);
        PopUpAlert('error', 'Please login and proceed to checkout', 'error').then(
          res => {
            document.getElementById('login_button').click();
          }
        );
      }
    };

    const showAlertNotEnoughBalance = () => {
       return Swal.fire({
        title: 'Insufficient Balance',
        text: "Please topup your wallet with Matic or checkout with Stripe. Thank you!",
        icon: 'error',
        //confirmButtonColor: "#ee1f78",
        confirmButtonColor: 'var(--color-gold)',
        iconColor: 'var(--color-gold)',
        customClass: { confirmButton: 'gold large stretch', cancelButton: 'large stretch' },
        confirmButtonText: 'Checkout with Stripe',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          createOrderWithStripe()
        }
    });
    };

    const handleBuyNFTWithMetaMask = async (
      nftPrice,
      assetId,
      quantity,
      saleType,
      orderId,
      sellerAddress
    ) => {
      let web3provider;

      if(!userWalletDetails.network) {
        web3provider = await props.connectWalletFunction();
      }

      web3provider = await getProvider(userWalletDetails.network);

      let web3 = new Web3(web3provider);
      // new Web3(Web3.givenProvider);

      let chainId;
      try {
        chainId = await web3.eth.getChainId();
      } catch (err) {}

      const networkVerify = await checkNetwork(chainId);
      if (!networkVerify) return setActive(false);
      setActive(true);
      setIsAuction(true);
      if (!sellerAddress) {
        sellerAddress = process.env.REACT_APP_ADMIN_ADDRESS;
      }

      // get auction smartcontract address from env file
      const contract_auction_address =
        process.env.REACT_APP_AUCTION_CONTRACT_ADDRESS;

      const nftAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;

      // creating smartcontract instance
      const auction_contract = await new web3.eth.Contract(
        contract_auction_abi,
        contract_auction_address
      );

      // account is an array of available accounts in connected wallet
      const account = await web3.eth.getAccounts();

      //wallet balance will return in wei (base unit of MATIC)
      let walletBalance = await web3.eth.getBalance(account[0]);

      // bid amount is converting to wei
      const nftPriceAmount = web3.utils.toWei(nftPrice.toString(), 'ether');
      if (Number(walletBalance) < Number(nftPriceAmount)) {
        showAlertNotEnoughBalance()
        setActive(false);
        setIsAuction(false);
        return;
      }
      //calculate gasprice
      function getGasPrice() {
        return web3.eth.getGasPrice();
      }

      const buyNFTMethod = auction_contract.methods.buyNft(
        // assetId,
        // quantity || 1,
        // nftAddress,
        // sellerWallet,
        // (1)
        [
          // 1: fixedPrice, 2: Auction,
          {
            _tokenId: assetId,
            _quantity: quantity || 1,
            _nftAddress: nftAddress,
            _sellerAddress: sellerAddress,
            _saleType: 1
          }
        ]
      );
      let txObject = null;

      try {
        const gasPrice = await getGasPrice();
        //creating transaction object
        txObject = {
          from: `${account[0]}`,
          value: nftPriceAmount,
          data: buyNFTMethod.encodeABI(),
          to: contract_auction_address,
          gasPrice: toHex(toBN(Math.ceil(gasPrice * 2))),
          gas: toHex(2100000)
        };
        //sending bid transaction through metamask
        web3.eth
          .sendTransaction({ ...txObject })
          .on('confirmation', function (confirmationNumber, receipt) {
            if (confirmationNumber === 24 && receipt.status) {
              let config = {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
                }
              };
              axiosInstance
                .post(`asset/${productInfo?.id}/crypto-paid`, { orderId }, config)
                .then(res => {
                  setActive(false);

                  if (res?.data?.success) {
                    PopUpAlert('Great!', res?.data?.message, 'success').then(
                      res => {
                        getBalanceToken();
                        window.location.reload();
                      }
                    );
                  } else {
                    PopUpAlert('Alert', res?.data?.message, 'error');
                  }
                })
                .catch(err => {
                  setActive(false);
                  PopUpAlert('Alert!', err.response?.data?.message, 'error');
                });
            }
          })
          .on('error', err => {
            setActive(false);
            PopUpAlert(
              'Alert!',
              `Buy NFT with Crypto Fail ${err.message}`,
              'error'
            );
          });
      } catch (err) {
        setActive(false);
        setIsAuction(false);
        PopUpAlert('Alert!', err, 'error').then(err => window.location.reload());
      }
    };

    /*
    const handleBuy = async () => {
        console.warn('handleBuy()');
        alert('handleBuy()');

        console.warn('userDetails', userDetails);

        if (userDetails == null) {
            //startCheckoutProcess();
            //chooseWalletType();
            props.chooseWalletType(productDetails?.id);
            return;
        }

        let web3 = connector.connected
            ? new Web3(provider)
            : new Web3(Web3.givenProvider);

        if ((!web3.currentProvider && !connector.connected) || !walletStatus) {
            // startCheckoutProcess();
            props.showCheckoutModal();
            return;
        }

        // startCheckoutProcess();
        // showCheckoutModal();

        // Previous custom checkout form
        props.showCheckoutModal();
    };
    */

    /*
    const chooseDeliveryAddressModal = () => {


        let htmlString = '<div>test</div>';

        Swal.fire({
              title: 'Choose Delivery Address'
            , showCloseButton: true
            , showConfirmButton: true
            , closeButtonHtml: '<div style="padding:30px;"><img src="/images/icons/close-icon.svg" /></div>'
            , buttonsStyling: false
            , html: htmlString
        //})
        }).then((result) => {
            if (result.value) {

                showAddDeliveryAddressModal();
            }
        });
    };
    */

    const toAddress = decryptData('0x0af02a9f1262fca76388e25ef0840d8043458c6e');
    //
    //

    /*
    const showModalDialogForm = () => {
      Swal.withForm({
        title: 'Cool example',
        text: 'Any text that you consider useful for the form',
        showCancelButton: true,
        confirmButtonColor: '#DD6B55',
        confirmButtonText: 'Get form data!',
        closeOnConfirm: true,
        formFields: [
          { id: 'name', placeholder: 'Name Field' },
          { id: 'nickname', placeholder: 'Add a cool nickname' }
        ]
        //, function(isConfirm) {
        // do whatever you want with the form data
        // // { name: 'user name', nickname: 'what the user sends' }
      });
    };
    */

    /*
    //resell part code
    const resaleHandleClick = (e, id) => {
      e.stopPropagation();
      setOrderId(id);
      setResalePopup(true);
    };
    */

    const closeModal = () => {
      setResalePopup(false);
    };

    const getConfig = () => {
      let config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
        }
      };
      /*
      if (!localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)) {
          PopUpAlert('Alert..', 'No wallet connection found', 'error')
          return
      }
      */
      return config;
    };

    const resellMethod = async price => {
      const data = {
        orderId: orderId,
        regularPrice: +price
      };
      const config = getConfig();
      await axiosInstance
        .post('/asset/resale', data, config)
        .then(res => {
          setResalePopup(false);
        })
        .catch(err => {
          PopUpAlert(
            'Alert',
            err?.message ? err?.message : err?.data?.response?.message,
            'error'
          );
        });
    };

    /*
    const handleBidplace = async (bidamt, assetId, bidamtdollar) => {
      const web3provider = await getProvider();
      //
      let web3 = new Web3(web3provider);
      // new Web3(Web3.givenProvider);

      let chainId;
      try {
        chainId = await web3.eth.getChainId();
      } catch (err) {

      }

      const networkVerify = await checkNetwork(chainId);
      if (!networkVerify) return setActive(false);
      setActive(true);
      setIsAuction(true);

      // get auction smartcontract address from env file
      const contract_auction_address =
        process.env.REACT_APP_AUCTION_CONTRACT_ADDRESS;

      const nftAddress = process.env.REACT_APP_NFT_CONTRACT_ADDRESS;
      const sellerWallet = process.env.REACT_APP_ADMIN_ADDRESS;

      // creating smartcontract instance
      const auction_contract = await new web3.eth.Contract(
        contract_auction_abi,
        contract_auction_address
      );


      // account is an array of available accounts in connected wallet
      const account = await web3.eth.getAccounts();

      //wallet balance will return in wei (base unit of MATIC)
      let walletBalance = await web3.eth.getBalance(account[0]);

      // bid amount is converting to wei
      const bid_amount = web3.utils.toWei(bidamt.toString(), 'ether');
      if (Number(walletBalance) < Number(bid_amount)) {
        PopUpAlert(
          'Alert!',
          "You don't have sufficient balance to bid. Please topup your wallet with Matic. Thank you.",
          'error'
        );
        setActive(false);
        setIsAuction(false);
        return;
      }
      //calculate gasprice
      function getGasPrice() {
        return web3.eth.getGasPrice();
        // return web3.utils.toBN(gasPrice).add(web3.utils.toBN('20000000000'));
      }

      //creating bid function object
      const bidMethod = auction_contract.methods.placeBid(
        assetId,
        bid_amount,
        nftAddress,
        sellerWallet
      );
      // .encodeABI();

      let gasEstimated = null;
      let txObject = null;

      try {
        const gasPrice = await getGasPrice();

        //creating transaction object
        txObject = {
          from: `${account[0]}`,
          value: bid_amount,
          data: bidMethod.encodeABI(),
          to: contract_auction_address,
          gasPrice: toHex(toBN(Math.ceil(gasPrice * 2))),
          gas: toHex(2100000)
        };
        //sending bid transaction through metamask
        web3.eth
          .sendTransaction({ ...txObject })
          .on('confirmation', function (confirmationNumber, receipt) {
            // debugger;
            if (confirmationNumber === 24 && receipt.status) {
              let config = {
                headers: {
                  Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
                }
              };
              axiosInstance
                .post(
                  `asset/auction/bid/${id}`,
                  {
                    bidAmountEth: bidamt,
                    // bidAmountEuro: (bidamt / euroValue).toFixed(2),
                    bidAmountEuro: bidamtdollar,
                    transactionHash: receipt.transactionHash
                  },
                  config
                )
                .then(res => {
                  setActive(false);
                  setIsAuction(false);
                  if (res?.data?.success) {
                    PopUpAlert('Great!', res?.data?.message, 'success').then(
                      res => {
                        getBalanceToken();
                        window.location.reload();
                      }
                    );
                  } else {
                    PopUpAlert('Alert', res?.data?.message, 'error');
                  }
                })
                .catch(err => {
                  setActive(false);
                  setIsAuction(false);
                  PopUpAlert('Alert!', err.response?.data?.message, 'error');
                });
            }
          })
          .on('error', err => {
            setActive(false);
            setIsAuction(false);
            PopUpAlert(
              'Alert!',
              `sending bid transaction through metamask Error: ${err}`,
              'error'
            );
          });
      } catch (err) {
        setActive(false);
        setIsAuction(false);

        if (err.code === 4001) {
          PopUpAlert('Alert!', 'Please confirm from your wallet', 'error').then(
            err => window.location.reload()
          );
        } else {
          PopUpAlert('Alert!', err, 'error');
          // .then(err => window.location.reload());
        }
      }
    };
    */

    const checkForUpcomingBid = bidStartDate => {
      let time = new Date(bidStartDate);
      var now = new Date();
      time < now ? setIsUpcomingBid(false) : setIsUpcomingBid(true);
    };

    const getProductDetails = async id => {
        console.warn('Item.getProductDetails('+id+')');

        if (id) {

            let config = {
                headers: {
                    Authorization: localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
                }
            };

            setLoaderActive(initialLoad);

            await axiosInstance
                .get(`/asset/view/${id}`, props.mode === 'view' ? config : '')
                .then(res => {

                    console.warn('    Item: res?.data?.result = ', res?.data?.result);
                    //console.warn('Item: res?.data?.result?.asset = ', res?.data?.result?.asset);

                    setProductDetails(res?.data?.result?.asset);
                    setProductInfo(res?.data?.result);

                    res?.data?.result?.asset.auctions?.[0]?.startDate
                    && checkForUpcomingBid(res?.data?.result?.asset.auctions?.[0]?.startDate);

                    // //
                    setInitialLoad(false);
                    setLoaderActive(false);

                    setCategories([
                        {
                              category: 'black'
                            , content: res?.data?.result?.asset.category?.name
                        }
                    ]);

                    /*
                    setSubCategories([
                      {
                        category: 'black',
                        content: res?.data?.result?.asset.subCategory?.name
                      }
                    ]);

                    setSubSubCategories([
                      {
                        category: 'black',
                        content: res?.data?.result?.league?.name
                      },
                      {
                        category: 'black',
                        content: res?.data?.result?.team?.name
                      }
                    ]);
                    */
                    
                    setProductPreviews(res?.data?.result?.asset?.files);
                  })
                  .catch(err => {
                    setLoaderActive(false);
                    PopUpAlert('Alert!', err.response?.data?.message, 'error').then(res =>
                      history.push('/')
                    );
                }); // axiosInstance.get()
        } // if (id)
    }; // getProductDetails(id)

    const handleEditionChange = e => {
      //
      const value = e.target.value;
      setFractionQuantity(value);
      if (value !== '') {
        setErrorFraction('');
      }
    };

    /*
    const handleClaim = async () => {
      let web3 = connector.connected ? new Web3(provider) : Web3Provider;

      const account = await web3.eth.getAccounts();

      setActive(true);
      try {
        const bidcontract = await web3.eth.sendTransaction({
          from: account[0],
          // to: contract_address,
          value: 0
          // data: auction_contract.methods.auctionEnd(productDetails?.id).encodeABI(),
        });
        setActive(false);
        await PopUpAlert('Great', 'NFT Claimed', 'success');
      } catch (err) {
        setActive(false);
        PopUpAlert(
          'Alert!',
          'Something went wrong, try again in sometime',
          'warning'
        );
      }
    };
    */

    const preventNonNumericalInput = e => {
      e = e || window.event;
      var charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
      var charStr = String.fromCharCode(charCode);
      const regex =
        !charStr.match(/^[0-9]+$/) && charStr !== '.' && charCode !== 8;
      if (regex) e.preventDefault();
    };

    //useEffect(() => {
    //}, [coinPaymentReq]);

    useEffect(() => {
      if (props.mode === 'view' && !location?.state?.fromPrevious) {
        history.push('/item');
      }
    }, []);

    //
    //

    const isOnSaleFlag = () => {
      if (productDetails?.isFractional) {
        if (productInfo?.userId == userWalletDetails.userId) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    };

    const sendViewProductEvent = (properties) => {
      return axiosInstance.post('/klaviyo/event', {
        event: "View Product",
        properties: {
          ...properties
        }
      })
    }

    const handleAddToCartAxios = async assetInfo => {
        console.warn("handleAddToCartAxios()", id);

        console.warn("assetInfo = ", assetInfo);
        console.warn("assetInfo.id = ", assetInfo.id);
        console.warn("walletUserDetailsObject = ", walletUserDetailsObject);

        //if (!walletUserDetailsObject) {
        if (!props.userDetails) {
            //PopUpAlert('Alert', 'Please log in or connect your wallet to add items to your cart.', 'error');
            PopUpWarning('Please log in or connect your wallet to add items to your cart.', null, 'error');
            return;
        }

        const config = getConfig();
        console.warn("config", config);

        setAttemptAddToCard((attempt => attempt + 1))

        await axiosInstance
            .post(`carts/${ assetInfo.id }/listedAssets/${ id }`, { quantity: 1 }, config)
            .then(res => {
                if (res.data.success) {
                    sendViewProductEvent({
                        addToCard: true,
                        initiateCheckout: true,
                        initiatePurchase: false
                    })
                    dispatch(countCart(res.data.result.totalItems));
                    history.push("/cart");
                }
            })
            .catch(error => {
                console.warn("error = ", error);
                console.warn("error?.message = ", error?.message);
                console.warn("error?.response = ", error?.response);
                console.warn("error?.response?.data = ", error?.response?.data);
                console.warn("error?.response?.data?.message = ", error?.response?.data?.message);

                let errorMessage = error?.response?.data.message;
                if (!errorMessage) {
                    errorMessage = error.err || error?.message
                        ? error?.message
                        : error?.data?.response?.message
                }

                PopUpWarning('Please log in or connect your wallet to add items to your cart.', null, 'error');

                PopUpAlert(
                    'Alert'
                    , errorMessage
                    , 'error'
                );
            });
    };

    const handleAddToCart = () => {
        console.warn("handleAddToCart()");

        console.warn("productInfo = ", productInfo);
        console.warn("productDetails = ", productDetails);
        console.warn("productInfo?.id = ", productInfo?.id);
        console.warn("productDetails?.id = ", productDetails?.id);

        //props.handleAddToCart(productDetails, productInfo);        
        //props.handleAddToCart(productDetails.id, productInfo.listedAssetId)
        props.handleAddToCart(productDetails.id, productInfo.id)
    };

    const handleAddToCartBak = async assetInfo => {
        console.warn("handleAddToCart()", id);

        console.warn("assetInfo = ", assetInfo);
        console.warn("assetInfo.id = ", assetInfo.id);
        console.warn("walletUserDetailsObject = ", walletUserDetailsObject);
        console.warn("props.userDetails = ", props.userDetails);

        //if (!walletUserDetailsObject) {
        //if (!props.userDetails) {
        if (
            !props.userDetails
            || !props.userDetails?.id
        ) {
            //PopUpAlert('Alert', 'Please log in or connect your wallet to add items to your cart.', 'error');
            PopUpWarning('Please log in or connect your wallet to add items to your cart.', null, 'error');
            return;
        }

        //const config = getConfig();
        //console.warn("config", config);

        setAttemptAddToCard((attempt => attempt + 1))

        let bodyObject = { quantity: 1 };

        let fetchConfig = {
            method: 'POST'
            , headers: {
                  //'Accept': 'application/json'
                  'Accept': '*/*'
                , 'Content-Type': 'application/json'
                , Authorization: 'Bearer ' + localStorage.getItem(NAMES_CONSTANTS.USER_ACCESS_TOKEN)
            }
            , body: JSON.stringify(bodyObject)
        };

        let fetchPath = `${ process.env.REACT_APP_API_URL }carts/${ assetInfo.id }/listedAssets/${ id }`;
        console.warn("fetchPath = ", fetchPath);
        let fetchPromise = fetch(fetchPath, fetchConfig);
        fetchPromise
            /*
            .then(res => {
                if (res.data.success) {
                    sendViewProductEvent({
                        addToCard: true,
                        initiateCheckout: true,
                        initiatePurchase: false
                    })
                    dispatch(countCart(res.data.result.totalItems));
                    history.push("/cart")
                }
            })
            */
            .then(function(response) {

                /*
                response.ok =  false
                response.status =  500
                response.statusText =  Internal Server Error
                responseJson =  null
                */
                console.warn("response = ", response);
                console.warn("response.ok = ", response.ok);
                console.warn("response.status = ", response.status);
                console.warn("response.statusText = ", response.statusText);

                if (response.ok) { // status of 200 signifies sucessful HTTP call, shorthand for checking that status is in the range 200-299 inclusive. Returns a boolean value.

                    Swal.close();

                    /*
                    sendViewProductEvent({
                        addToCard: true,
                        initiateCheckout: true,
                        initiatePurchase: false
                    });
                    */

                    //console.warn("response.body = ", response.body);
                    //console.warn("response.data = ", response.data);
                    //dispatch(countCart(res.data.result.totalItems));
                    //dispatch(countCart(res.data.result.totalItems));
                    //dispatch(countCart(response.data.result.totalItems));

                    //history.push("/cart")

                    return response.json();
                    //return response.text();

                } else {

                    /*
                    //PopUpWarning('Please log in or connect your wallet to add items to your cart.', null, 'error');
                    //let callbackFunction = function() { props.chooseWalletType(); }
                    let callbackFunction = function() { props.warninRegisterFunctions.showLoginDialog(); }
                    PopUpWarningThen('Please log in or connect your wallet to add items to your cart.', null, 'error', callbackFunction);
                    return null;
                    */

                    //if (response.status === 401) {
                    //    PopUpAlert('Alert', response.statusText, 'error');
                    //}
                    //PopUpAlert('Alert', response.statusText, 'error');

                    //if ()
                    //return response.text();
                    //const responseText = await response.text();
                    //response.text().then(function(responseText) {
                    response.json().then(function(responseText) {
                        console.warn("responseText = ", responseText);
                        //alert("responseText = " + responseText);
                        //PopUpAlert('Alert', response.statusText, 'error');
                        PopUpWarning(responseText.message, responseText.error, 'warning');
                    });
                }
            })

            .then(function(responseJson) {
                console.warn("responseJson = ", responseJson);
                //getProfileDetails();

                if (responseJson != null) {
                    sendViewProductEvent({
                        addToCard: true,
                        initiateCheckout: true,
                        initiatePurchase: false
                    });
                    
                    let totalItems = responseJson.result.totalItems;
                    console.warn("totalItems = ", totalItems);

                    dispatch(countCart(totalItems));

                    history.push("/cart");
                //} else {
                //    props.chooseWalletType();
                }
            })

            /*
            .then(function(responseText) {
                console.warn("responseText = ", responseText);
                //getProfileDetails();
            })
            //*/

            .catch(error => {
                console.warn("error = ", error);
                console.warn("error?.message = ", error?.message);
                /*
                console.warn("error?.response = ", error?.response);
                console.warn("error?.response?.data = ", error?.response?.data);
                console.warn("error?.response?.data?.message = ", error?.response?.data?.message);
                let errorMessage = error?.response?.data.message;
                if (!errorMessage) {
                    errorMessage = error.err || error?.message
                        ? error?.message
                        : error?.data?.response?.message
                }
                */

                //PopUpWarning('Please log in or connect your wallet to add items to your cart.', null, 'error');
                //PopUpAlert('Alert', errorMessage, 'error');
                PopUpAlert('Alert', error?.message, 'error');
            });
    };

    const handleCancelListing = async () => {
        if (id) {
            const config = getConfig();
            await axiosInstance
                .put('/asset/resale?id=' + id, {}, config)
                .then(res => {
                    PopUpAlert(
                        'Alert',
                        res.message || 'Asset sucessfully removed from Resell',
                        'success'
                    );
                })
                .catch(err => {
                    console.error('err', err?.error);
                    PopUpAlert(
                        'Alert'
                        , err.err || err?.message
                            ? err?.message
                            : err?.data?.response?.message
                        , 'error'
                    );
                });
        }
    };

    let productInfoIds = [ 'product-info', 'product-description', 'product-creator' ];

    /*
    const showProductTab = function(elementId) {
        productInfoIds.forEach(function(elementIdParameter) {
            document.getElementById(elementIdParameter).style.display = "none";
        });
        document.getElementById(elementId).style.display = "block";
    }
    */

    //if (productDetails) productDetails.shortDescription = 'The property is ideally located to potentially serve as a "last-mile" distribution facility, an asset class that we believe will continue to be crucial for many businesses and as a result experience outsized demand.';

    //let categoryName;
    let categoryName = productDetails?.category.name;

    //console.warn('productDetails = ', productDetails);
    //console.warn('productDetails?.strategy = ', productDetails?.strategy);

    /* Not possible here, productDetails is not available.
    let closingDate = productDetails.closingDate;
    console.warn('closingDate = ', closingDate);
    let millisecondsUntilClosing = Number(
          new Date().getTime()
        - new Date(
            Date.parse(
                productDetails.closingDate
            )
        //).getTime() - new Date().getTime()
        //).getTime() / (1000 * 60 * 60)
        ).getTime()
    ) / (1000 * 60 * 60 * 24);
    console.warn('millisecondsUntilClosing = ', millisecondsUntilClosing);
    */

    //console.warn('Item: productDetails = ', productDetails);
    console.warn('Item: productDetails = ', productDetails?.id, productDetails?.name);
    console.debug('Item: productDetails?.status = ', productDetails?.status);

    let fileIndex = 0;

    return (
        <>

        <main
            className = { cn("item-main", styles.main) }>

            {
                loaderActive
                && (
                    <div className={styles.loaderContent}>
                        <CircularProgress />
                    </div>
                )
            }

            {
                resalePopup
                && (
                    <ResellModal
                        closeModal = { closeModal }
                        resellMethod = { resellMethod }
                        item = { productDetails }
                        productInfo = { productInfo }
                        loaderShow = { setLoaderActive }
                        //getAllAssets = { getAllAssets }
                        />
                )
            }

            <section
                id = 'item-section'
                className = { 'form' }
                style = {{
                    background: 'var(--blue-800, #052C65)'
                }}>

                <div
                    id = 'item-wrapper'
                    className = { 'max-width' }>
                    {
                        productDetails
                        && (
                            <>

                            <div className = {
                                cn(
                                  'item-container'
                                  , styles.itemContainer
                                )}>

                                <div
                                    className={styles.userDetails}
                                    style = {{
                                        paddingRight: "30px"
                                    }}>

                                    <div className={styles.assetName}>{ productDetails.name }</div>

                                    <>
                                    <div>

                                        {/*
                                        <div className={styles.cost}>
                                            {
                                                productInfo?.saleType === 'fixed'
                                                ? (
                                                    <>
                                                    <div className={styles.price}>
                                                        &#36;{' '}
                                                        {displayPrice(productInfo, exchangeRate)}
                                                    </div>
                                                    </>
                                                )
                                                : (
                                                    <>
                                                    <div className={styles.price}>
                                                      &#36;
                                                      {(
                                                        productDetails?.auctions?.[0]?.currentBid ||
                                                        productDetails?.auctions?.[0]?.openingPrice
                                                      )?.toFixed(4)}
                                                    </div>
                                                    <div className={styles.price}>
                                                          ({
                                                              ((productDetails?.auctions?.[0]?.currentBid || productDetails?.auctions?.[0]?.openingPrice) * euroValue)?.toFixed(4)
                                                          }{' '}Matic)
                                                    </div>
                                                    </>
                                                )
                                            }
                                        </div>
                                        */}

                                        <div style = {{
                                                //color: "var(--color-gray-white, var(--White, #FFF))"
                                                color: "var(--color-gray-white)"
                                                //, fontFamily: "'Source Sans 3'"
                                                , fontFamily: "'Open Sans Light'"
                                                , fontSize: "15px"
                                                , fontStyle: "normal"
                                                , fontWeight: "400"
                                                , lineHeight: "150%" /* 24px */ 
                                                , padding: "10px 0px 13px 0px"
                                            }}>
                                            { productDetails?.location }
                                        </div>

                                        {
                                            productDetails?.intro
                                            && (
                                                <div>
                                                    <div
                                                      className={cn(
                                                        'textAlignLeft',
                                                        styles.subDescription
                                                      )}>
                                                      {shortLimit < 451 &&
                                                      productDetails?.intro.length < 451 ? (
                                                        productDetails?.intro
                                                      ) : (
                                                        <>
                                                          {' '}
                                                          {productDetails?.intro.substr(
                                                            0,
                                                            shortLimit
                                                          )}
                                                          {shortLimit < 451 && (
                                                            <div
                                                              onClick={() => setShortLimit(1501)}
                                                              className={styles.readMore}>
                                                              Read more...
                                                            </div>
                                                          )}
                                                          {shortLimit > 451 && (
                                                            <div
                                                              onClick={() => setShortLimit(450)}
                                                              className={styles.readMore}>
                                                              Read Less...
                                                            </div>
                                                          )}
                                                        </>
                                                      )}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                    {/*
                                    <div
                                        style = {{
                                            paddingTop: "18px"
                                        }}
                                        >
                                        <button style = {{
                                              color: "var(--color-gray-900, #212529)"
                                            , textAlign: "center"
                                            , fontFamily: "'Source Sans 3'"
                                            , fontSize: "20px"
                                            , fontStyle: "normal"
                                            , fontWeight: "600"
                                            , lineHeight: "150%"
                                            , padding: "10px 20px"
                                            , borderRadius: "10px"
                                            }}>
                                            <img src = '/images/icons/file-pdf-fill.svg'
                                                style = {{
                                                    paddingRight: "10px"
                                                }}
                                                />
                                        <button className = 'theme-success large'>
                                            Access the Data Room
                                        </button>
                                    </div>
                                    */}

                                    {
                                        productDetails?.status != 'past-deal'
                                        && (
                                            <>
                                            <div
                                                style = {{
                                                    paddingTop: "18px"
                                                }}
                                                >
                                                <button
                                                    className = 'theme-alert large'
                                                    onClick = {() => { PopUpAlert('Info', 'This will link to the external Data Room', 'info') }}
                                                    >
                                                    Access the Data Room
                                                </button>
                                            </div>

                                            <div
                                                style = {{
                                                    paddingTop: "18px"
                                                }}
                                                >
                                                <button
                                                    type = 'button'
                                                    //className = 'theme-primary large'
                                                    className = 'theme-success large'
                                                    //onClick = {() => props.handleAddToCart(productDetails) }
                                                    //onClick = {() => props.handleAddToCart(productDetails, productInfo) }
                                                    onClick = {() => handleAddToCart() }
                                                    >Invest Now</button>
                                            </div>
                                            </>
                                        )
                                    }
                                    </>
                                </div>


                                {/*
                                <div className={ "tabbed-product-description" }>
                                */}
                                <div className={ "item-details" }>

                                    {/*
                                    <div>
                                        <div className={ cn("table", "header") }>
                                            <div className={'row'}>
                                                <div className={ "cell" }
                                                    onClick={() => {
                                                        showProductTab('product-info');
                                                    }}
                                                    >Info</div>
                                                <div className={ "cell" }
                                                    onClick={() => {
                                                        showProductTab('product-description');
                                                    }}
                                                    >Description</div>
                                                
                                                <div className={ "cell" }
                                                    //onclick="showProductTab('product-creator')"
                                                    onClick={() => {
                                                        showProductTab('product-creator');
                                                    }}>
                                                    Creator
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    */}

                                    <div
                                        id='product-info'
                                        className = {
                                            cn(
                                                "item-details-table-wrapper"
                                                , styles.tabbedProductDescription
                                            )
                                        }
                                        style = {{
                                            borderRadius: '10px'
                                            , border: '1px solid var(--color-gray-300, #DEE2E6)'
                                            , background: 'var(--White, #FFF)'
                                            /*
                                            , background: (
                                                productDetails.status == 'past-deal'
                                                ? 'var(--color-gray-200)'
                                                : 'var(--White, #FFF)'
                                            )
                                            , color: (
                                                productDetails.status == 'past-deal'
                                                ? 'var(--color-gray-600)'
                                                : 'var(--color-gray-700)'
                                            )
                                            */
                                        }}>

                                        {/*
                                        <div className={ "table body" }>
                                        */}
                                        <div
                                            className = { cn(
                                                'table'
                                                , 'item-details-table'
                                                , styles.tableBody
                                            )}
                                            >

                                            <div className={'row'}>

                                                <div
                                                    className = 'cell icon'
                                                    style = {{ paddingLeft: "20px" }}
                                                    >
                                                    <img
                                                        //src = '/images/icons/building.svg'
                                                        //src = '/images/icons/svgrepo/building-business-management-svgrepo-com.svg'
                                                        src = '/images/icons/tags-icon-black.png'
                                                        //className = { "filter-body-text-color" }
                                                        className = 'filter-body-text-color'
                                                        //style = {{ fill: "red" }}
                                                        />
                                                </div>

                                                <div
                                                    className = 'cell label'
                                                    //dataShortName = 'Asset Category'
                                                    //data-short-name = 'Asset Category'
                                                    //dataLongName = 'Asset Category'
                                                    //data-long-name = 'Asset Category'
                                                    >
                                                    {/*
                                                    Asset Category
                                                    Category
                                                      */}
                                                    <span className = 'long-name'>
                                                        Asset Category
                                                    </span>
                                                    <span className = 'short-name'>
                                                        Category
                                                    </span>
                                                </div>

                                                <div
                                                    className = {
                                                        cn(
                                                            'cell'
                                                            //, categoryName
                                                        )
                                                    }
                                                    style = {{
                                                          color: 'var(--color-gray-800, #343A40)'
                                                        , fontFamily: 'Source Sans 3'
                                                        , fontSize: '16px'
                                                        , fontStyle: 'normal'
                                                        , fontWeight: '700' 
                                                    }}
                                                    >
                                                    <div
                                                        className = {
                                                            cn(
                                                                "item"
                                                                //, categoryName
                                                                , styles.item
                                                            )
                                                        }
                                                        //className = { cn("item", "industrial", styles.item)}
                                                        onClick = { () => history.push("/search/" + categoryName) }
                                                        //onClick = { () => clickCategory(getCategoryName(x?.asset.categoryId)) }
                                                        style = {{
                                                            //display: 'absolute'
                                                            position: 'relative'
                                                            //, right: '0'
                                                        }}>

                                                        {/*
                                                            productDetails?.status == 'past-deal'
                                                            &&
                                                                <div
                                                                    style = {{
                                                                        //color: 'white'
                                                                        display: 'flex'
                                                                        //, marginLeft: '8px'
                                                                        , margin: 'auto'
                                                                        , position: 'absolute'
                                                                        //, right: '0'
                                                                        , top: '-40px'
                                                                    }}>
                                                                    <div
                                                                        style = {{
                                                                              backgroundColor: 'red'
                                                                            , borderTopLeftRadius: '5px'
                                                                            , borderTopRightRadius: '5px'
                                                                            //, display: 'flex'
                                                                            , color: 'white'
                                                                            //, paddingLeft: '8px'
                                                                            //, paddingRight: '8px'
                                                                            , padding: '5px 10px'
                                                                            , fontSize: '14px'
                                                                            , fontWeight: '700'
                                                                            , verticalAlign: 'top'
                                                                            , fontFamily: 'DM Sans'
                                                                            , textAlign: 'center'
                                                                            , textTransform: 'none'
                                                                        }}>
                                                                        Past Deal
                                                                    </div>
                                                                </div>
                                                        */}

                                                        <div
                                                            //className = { categoryName }
                                                            //className = { cn(categoryName, 'text-shadow') }
                                                            style = {{
                                                                //display: 'absolute'
                                                                position: 'absolute'
                                                                , right: '0'
                                                                //, top: '-16px'
                                                                , bottom: '-16px'
                                                                , whiteSpace: 'nowrap'
                                                            }}
                                                            >

                                                            {
                                                                productDetails?.status == 'past-deal'
                                                                &&
                                                                    <div
                                                                        style = {{
                                                                            //color: 'white'
                                                                            display: 'flex'
                                                                            //, marginLeft: '8px'
                                                                            , margin: 'auto'
                                                                            //, position: 'absolute'
                                                                            //, right: '0'
                                                                            //, top: '-40px'
                                                                            //, border: 'red 1px solid'
                                                                            , textAlign: 'center'
                                                                        }}>
                                                                        <div
                                                                            style = {{
                                                                                  padding: '3px 8px'
                                                                                , fontSize: '16px'
                                                                                , fontWeight: '500'
                                                                                , verticalAlign: 'top'
                                                                                , fontFamily: 'DM Sans'
                                                                                , textAlign: 'center'
                                                                                , textTransform: 'none'
                                                                                , border: 'red 2px solid'
                                                                                , borderWidth: '2px 2px 0px 2px'
                                                                                , backgroundColor: 'white'
                                                                                , borderTopLeftRadius: '5px'
                                                                                , borderTopRightRadius: '5px'
                                                                                , margin: 'auto'
                                                                                , color: 'red'
                                                                                , boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)'
                                                                            }}>
                                                                            Past Deal
                                                                        </div>
                                                                    </div>
                                                            }

                                                            <button
                                                                className = { cn(categoryName, 'text-shadow') }
                                                                style = {{
                                                                    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.5)'
                                                                }}
                                                                >
                                                                { categoryName }
                                                            </button>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            {/*
                                            <div className = 'row'>
                                                <div className = 'cell icon'>
                                                    <img
                                                        //src='/images/icons/graph-up.svg'
                                                        src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                                        />
                                                </div>
                                                <div className = 'cell label'>
                                                    % Owned
                                                    % Already Pre-Owned
                                                </div>
                                                <div className={'cell'}>
                                                    { productInfo.percentageOwned }
                                                </div>
                                            </div>

                                            <div className = 'row'>
                                                <div className = 'cell icon'>
                                                    <img
                                                        //src='/images/icons/graph-up.svg'
                                                        src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                                        />
                                                </div>
                                                <div className = 'cell label'>
                                                    % Available to RealSplit
                                                </div>
                                                <div className={'cell'}>
                                                    { productInfo.percentageAvailable 
                                                </div>
                                            </div>
                                            */}

                                            {
                                                productDetails.percentageOwned != null
                                                && productDetails.percentageOwned !== 0.0
                                                && productDetails.percentageOwned !== 'N/A'
                                                ?
                                                    <> 
                                                    <div className = 'row'>
                                                        <div className = 'cell icon'>
                                                            <img
                                                                //src='/images/icons/graph-up.svg'
                                                                //src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                                                //src = '/images/icons/98913.png'
                                                                //src = '/images/icons/percentage-sign.png'
                                                                //src = '/images/icons/percentage-owned.png'
                                                                //src = '/images/icons/fractions-available.png'
                                                                //src = '/images/icons/percentage-owned.png'
                                                                src = '/images/icons/fractions-available.png'
                                                                style = {{ transform: 'rotate(180deg)' }}
                                                                />
                                                        </div>
                                                        <div className = 'cell label'>
                                                            <div>
                                                                <span className = 'long-name'>
                                                                    % Already Pre-Owned
                                                                </span>
                                                                <span className = 'short-name'>
                                                                    % Pre-Owned
                                                                </span>
                                                            </div>
                                                            <div className = 'long-name'
                                                                //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                                                >
                                                                {/*
                                                                The percentage of the property that is owned by the current owners.
                                                                The percentage of the deal that will remain owned by the current owners.
                                                                */}
                                                                The percentage of the property that is currently owned and that will remain owned by the current owners
                                                            </div>
                                                        </div>
                                                        <div className = { 'cell' }>
                                                            {
                                                                productDetails.percentageOwned != null && productDetails.percentageOwned !== 0.0
                                                                ? productDetails.percentageOwned
                                                                : 'N/A'
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className = 'row'>
                                                        <div className = 'cell icon'>
                                                            <img
                                                                //src='/images/icons/graph-up.svg'
                                                                //src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                                                //src = '/images/icons/percentage-sign.png'
                                                                //src = '/images/icons/fractions-available.png'
                                                                //src = '/images/icons/fractions-available.png'
                                                                src = '/images/icons/percentage-owned.png'
                                                                />
                                                        </div>
                                                        <div className = 'cell label'>
                                                            <div>
                                                                <span className = 'long-name'>
                                                                    % Available for Fractionalization
                                                                </span>
                                                                <span className = 'short-name'>
                                                                    % Available
                                                                </span>
                                                            </div>
                                                            <div
                                                                className = 'long-name'
                                                                //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                                                >
                                                                {/*
                                                                The percentage of the deal that is available for fractionalized ownership by RealSplit™ investors
                                                                */}
                                                                The percentage of the property that is available for fractionalized ownership by RealSplit™ investors
                                                            </div>
                                                        </div>
                                                        <div className = { 'cell' }>
                                                            { productDetails?.percentageAvailable }
                                                        </div>
                                                    </div>
                                                    </> 
                                                :
                                                    null
                                            }

                                            <div className = 'row'>
                                                <div className = 'cell icon'>
                                                    <img
                                                        //src='/images/icons/graph-up.svg'
                                                        //src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                                        //src = '/images/icons/1819867-200.png'
                                                        src = '/images/icons/dollar-sign-transparent.png'
                                                        />
                                                </div>
                                                <div className = 'cell label'>
                                                    <div>
                                                    <span className = 'long-name'>
                                                        Investment Size
                                                    </span>
                                                    <span className = 'short-name'>
                                                        Size
                                                    </span>
                                                    </div>

                                                    <div
                                                        className = 'long-name'
                                                        >
                                                        {/*
                                                        */}
                                                        The total amount of capital to be raised
                                                    </div>
                                                </div>
                                                <div className = { 'cell' }>
                                                    { productDetails.investmentSize }
                                                </div>
                                            </div>

                                            <div className = 'row'>
                                                <div className = 'cell icon'>
                                                    <img
                                                        src = '/images/icons/dollar-sign-transparent.png'
                                                        //style = {{ transform: 'rotate(90deg)' }}
                                                        />
                                                </div>
                                                <div className = 'cell label'>
                                                    <div>
                                                        <span className = 'long-name'>
                                                            Amount Remaining
                                                        </span>
                                                        <span className = 'short-name'>
                                                            Remaining
                                                        </span>
                                                    </div>
                                                    <div
                                                        className = 'long-name'
                                                        //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                                        >
                                                        The amount of capital remaing to be raised and available to RealSplit investors
                                                    </div>
                                                </div>
                                                <div className = { 'cell' }>
                                                    {/*
                                                    ${
                                                        numberWithCommas(productInfo?.availableFractions * productDetails?.regularPrice)//.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES))
                                                    }
                                                    */}
                                                    ${
                                                        productInfo?.availableFractions == 0
                                                        ? 
                                                            0
                                                        :
                                                            numberWithCommas(productInfo?.availableFractions * productDetails?.regularPrice)//.toFixed(CRYPTOCURRENCY_CONSTANTS.CRYPTO_PRICE_DECIMAL_PLACES))
                                                    }
                                                </div>
                                            </div>

                                            {
                                                productDetails?.minimumInvestmentAmount
                                                &&
                                                    <div className = 'row'>
                                                        <div className = 'cell icon'>
                                                            <img
                                                                //src='/images/icons/graph-up.svg'
                                                                //src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                                                src = '/images/icons/dollar-sign-transparent.png'
                                                                />
                                                        </div>
                                                        <div className = 'cell label'>
                                                            <div>
                                                                <span className = 'long-name'>
                                                                    Minimum Investment Amount
                                                                </span>
                                                                <span className = 'short-name'>
                                                                    Minimum
                                                                </span>
                                                            </div>
                                                            <div
                                                                className = 'long-name'
                                                                //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                                                >
                                                                { productDetails?.minimumInvestment / productDetails?.regularPrice } Splits X ${ productDetails?.regularPrice } per Split
                                                            </div>
                                                        </div>
                                                        <div className = { 'cell' }>
                                                            { productDetails?.minimumInvestmentAmount }
                                                        </div>
                                                    </div>
                                            }

                                            {/*
                                            <div className = 'row'>
                                                <div className = 'cell icon'>
                                                    <img
                                                        //src='/images/icons/graph-up.svg'
                                                        src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                                        />
                                                </div>
                                                <div className = 'cell label'>
                                                    <span className = 'long-name'>
                                                        Total Splits
                                                    </span>
                                                    <span className = 'short-name'>
                                                        Total Splits Available
                                                    </span>
                                                </div>
                                                <div className = {' cell' }>
                                                    { productInfo.totalFractions }
                                                </div>
                                            </div>
                                            */}

                                            {/* 
                                            <div className = 'row'>
                                                <div className = 'cell icon'>
                                                    <img
                                                        //src='/images/icons/graph-up.svg'
                                                        src = '/images/icons/svgrepo/business-folder-management-svgrepo-com.svg'
                                                        />
                                                </div>
                                                <div className = 'cell label'>
                                                    Splits Remaining
                                                </div>
                                                <div className={'cell'}>
                                                    { productInfo.availableFractions }
                                                </div>
                                            </div>
                                            */}

                                            {
                                                productInfo?.totalFractions > 0
                                                &&
                                                    <>
                                                    <div className = 'row'>
                                                        <div className = 'cell icon'>
                                                            <img
                                                                //src='/images/icons/graph-up.svg'
                                                                //src = '/images/icons/black-pie-chart-75-percent-15676.png'
                                                                //src = '/images/icons/fractions-available.png'
                                                                src = '/images/icons/six-fractions.png'
                                                                //src = '/images/icons/black-pie-chart-75-percent-15676.png'
                                                                //style = {{ transform: 'rotate(90deg)' }}
                                                                />
                                                        </div>
                                                        <div className = 'cell label'>
                                                            <div>
                                                                <span className = 'long-name'>
                                                                    Total Splits Available
                                                                </span>
                                                                <span className = 'short-name'>
                                                                    Total Splits
                                                                </span>
                                                            </div>
                                                            <div
                                                                className = 'long-name'
                                                                //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                                                >
                                                                {/*
                                                                (calculated by dividing the size of the deal by the price per fraction)
                                                                The total amount of Splits available to RealSplit™ investors
                                                                */}
                                                                The total amount of Splits available in this deal <br/>INVESTMENT SIZE / COST PER SPLIT
                                                            </div>
                                                        </div>
                                                        <div className = { 'cell' }>
                                                            { productInfo?.totalFractions }
                                                        </div>
                                                    </div>

                                                    <div className = 'row'>
                                                        <div className = 'cell icon'>
                                                            <img
                                                                //src='/images/icons/graph-up.svg'
                                                                //src = '/images/icons/black-pie-chart-75-percent-15676.png'
                                                                //src = '/images/icons/six-fractions-one-taken.png'
                                                                src = '/images/icons/six-fractions-two-taken.png'
                                                                //src = '/images/icons/fractions-available.png'
                                                                //style = {{ transform: 'rotate(90deg)' }}
                                                                />
                                                        </div>
                                                        <div className = 'cell label'>
                                                            <div>
                                                                <span className = 'long-name'>
                                                                    Splits Remaining
                                                                </span>
                                                                <span className = 'short-name'>
                                                                    Available
                                                                </span>
                                                            </div>
                                                            <div
                                                                className = 'long-name'
                                                                //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                                                >
                                                                Splits remaining to be sold (available to be bought) by RealSplit investors
                                                            </div>
                                                        </div>
                                                        <div className = { 'cell' }>
                                                            { productInfo?.availableFractions }
                                                        </div>
                                                    </div>
                                                    </>
                                            }

                                            <div
                                                className = { 'row' }
                                                //style = {{ display: 'none' }}
                                                >

                                                <div
                                                    className={'cell icon'}
                                                    //style = {{ padding: "0px 20px" }}
                                                    >
                                                    <img
                                                        //src='/images/icons/clock.svg'
                                                        //src = '/images/icons/svgrepo/clock-v-svgrepo-com.svg'
                                                        //src = '/images/icons/time-schedule-clock-svgrepo-com.png'
                                                        src = '/images/icons/calendar-icon.png'
                                                        />
                                                </div>

                                                <div className = {
                                                    cn(
                                                          'cell'
                                                        , 'label'
                                                        //, styles.label
                                                    )
                                                    }>
                                                    <span className = 'long-name'>
                                                        Investment Deadline
                                                    </span>
                                                    <span className = 'short-name'>
                                                        Deadline
                                                    </span>
                                                </div>

                                                <div
                                                    //className = { cn('cell', 'bold') }
                                                    className = 'cell'
                                                    //style = {{
                                                    //    textTransform: "capitalize"
                                                    //}}
                                                    >

                                                    {
                                                        productDetails?.status != 'past-deal'
                                                        ?
                                                            <div style = {{
                                                                display: 'flex'
                                                                //, flexWrap: 'wrap'
                                                                //, flexDirection: 'row'
                                                                , justifyContent: 'flex-end'
                                                                , flexWrap: 'nowrap'
                                                                , flexDirection: 'column'
                                                                }}>

                                                                <div
                                                                    style = {{
                                                                        whiteSpace: 'nowrap'
                                                                        , fontSize: '66%'
                                                                        , fontWeight: 'normal'
                                                                        , color: 'var(--color-gray-600)'
                                                                        , textTransform: "initial"
                                                                        , lineHeight: '20px'
                                                                    }}>
                                                                    
                                                                    ({
                                                                        // Check if the closing date is in the past or future
                                                                        new Date() > new Date(Date.parse(productDetails.closingDate))

                                                                        ? [ // "Past"
                                                                            Math.round(
                                                                                  Number(
                                                                                        new Date().getTime()
                                                                                      - new Date(Date.parse(productDetails.closingDate)).getTime()
                                                                                  ) / (1000 * 60 * 60 * 24)
                                                                            )
                                                                            , "Days ago"
                                                                        ].join(' ')

                                                                        : [ // "Future"
                                                                            Math.round(
                                                                                  Number(
                                                                                        new Date(Date.parse(productDetails.closingDate)).getTime()
                                                                                      - new Date().getTime()
                                                                                  ) / (1000 * 60 * 60 * 24)
                                                                            )
                                                                            , "Days"
                                                                        ].join(' ')
                                                                    })
                                                                </div>

                                                                <div
                                                                    style = {{
                                                                        whiteSpace: 'nowrap'
                                                                        , lineHeight: '30px'
                                                                    }}
                                                                    >
                                                                    &nbsp;
                                                                    { 
                                                                        new Date(
                                                                            Date.parse(productDetails.closingDate)
                                                                        )
                                                                        .toLocaleDateString(
                                                                            "en-US"
                                                                            //, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
                                                                            //, { year: 'numeric', month: 'long', day: 'numeric' }
                                                                            , { year: 'numeric', month: 'short', day: 'numeric' }
                                                                        )
                                                                    }
                                                                </div>

                                                            </div>
                                                        :
                                                            <div>
                                                                CLOSED
                                                            </div>
                                                    }

                                                </div>
                                            </div>

                                            {/*
                                            {
                                                productDetails?.holdingPeriod != null
                                                &&
                                            <div className = 'row'>
                                                <div className = 'cell icon'>
                                                    <img
                                                        //src = '/images/icons/calendar2.svg'
                                                        //src = '/images/icons/svgrepo/business-calendar-management-svgrepo-com.svg'
                                                        src = '/images/icons/png-clipart-padlock-computer-icons-security-padlock-technic-logo-thumbnail.png'
                                                        />
                                                </div>
                                                <div className = 'cell label'>
                                                    <span className = 'long-name'>
                                                        Holding Period
                                                    </span>
                                                    <span className = 'short-name'>
                                                        Holding
                                                    </span>
                                                </div>
                                                <div className = { 'cell' }>
                                                    { productDetails.holdingPeriod }
                                                </div>
                                            </div>
                                            }
                                            */}

                                            {/*
                                            <div className = 'row'>
                                                <div className = 'cell icon'>
                                                    <img
                                                        //src='/images/icons/people.svg'
                                                        //src='/images/icons/svgrepo/business-management-media-7-svgrepo-com.svg'
                                                        //src='/images/icons/connected-people-icon.svg'
                                                        src='/images/icons/referred-affiliates-1.png'
                                                        />
                                                </div>
                                                <div
                                                    //className = { cn('cell', styles.label) }
                                                    className = 'cell label'
                                                    >
                                                    Investors
                                                    Current Investors in deal
                                                </div>
                                                <div className={'cell'}>
                                                    0
                                                </div>
                                            </div>
                                            */}

                                            <div className = 'row'>
                                                <div className = 'cell icon'>
                                                    <img
                                                        //src='/images/icons/graph-up.svg'
                                                        //src = '/images/icons/svgrepo/business-grafik-management-svgrepo-com.svg'
                                                        //src = '/images/icons/return-on-investment-icon.png'
                                                        //src = '/images/icons/bar-chart-up-3.svg'
                                                        src = '/images/icons/noun-increase-212057-cropped.png'
                                                        />
                                                </div>
                                                <div className = 'cell label'>
                                                    {/*
                                                    ROI
                                                    */}
                                                    <div>
                                                                <span className = 'long-name'>
                                                        Return on Investment
                                                    </span>
                                                    <span className = 'short-name'>
                                                        Return
                                                    </span>
                                                            </div>
                                                            <div
                                                                className = 'long-name'
                                                                //style = {{ fontSize: '.6em', lineHeight: '12px', margin: '4px 0px', textTransform: 'none' }}
                                                                >
                                                                Annualized based on 5-year hold
                                                            </div>
                                                    
                                                </div>
                                                <div className = { 'cell' }>
                                                    { productDetails.returnOnInvestment }
                                                </div>
                                            </div>

                                            {/*
                                            <div className={'row'}>
                                                <div className={'cell'}>
                                                    <img src='/images/icons/svgrepo/asset-management-icon.svg' />
                                                </div>
                                                <div className={cn('cell', styles.label)}>
                                                    Price
                                                </div>
                                                <div className={'cell'}>
                                                    ${displayPrice(productInfo, exchangeRate)} ({displayCryptoPrice(productInfo)} Matic)
                                                </div>
                                            </div>
                                            
                                            <div className={'row'}>
                                                <div className={cn('cell', styles.label)}>
                                                    Total Editions
                                                </div>
                                                <div className={'cell'}>{ productInfo?.asset?.totalEdition }</div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={cn('cell', styles.label)}>
                                                    Editions left in this Asset
                                                </div>
                                                <div className={'cell'}>{ productInfo?.asset?.availableStock }</div>
                                            </div>

                                            <div className={'row'}>
                                                <div className={cn('cell', styles.label)}>
                                                    Creator
                                                </div>
                                                <div className={'cell'}>{productDetails?.creatorName}</div>
                                            </div>
                                            */}

                                        </div>
                                    </div>

                                    {/*
                                    <div id='product-description' style={{ display: 'none' }}>
                                        Test description. This is some test content.
                                    </div>

                                    <div id='product-creator' style={{ display: 'none' }}>
                                        <div className={'table'}>
                                            <div
                                                className={'cell'}
                                                style={{ paddingRight: '10px' }}>
                                                <img
                                                    src='/images/avatar-icon-large.png'
                                                    style={{ width: '50px', verticalAlign: 'middle' }}
                                                    />
                                            </div>
                                            <div
                                                className={'cell'}
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    lineHeight: '24px',
                                                    verticalAlign: 'middle'
                                                }}>
                                                { productDetails?.creatorName }
                                            </div>
                                        </div>
                                    </div>
                                    */}

                                </div>

                            </div>

                            {
                                productDetails?.status != 'past-deal'
                                && (
                                    <div
                                        style = {{
                                            textAlign: "center"
                                            , margin: "auto"
                                            , padding: '50px 0px 0px 0px'
                                        }}>
                                        {/*
                                        <button
                                            type="button"
                                            //className={cn("dark")}
                                            //className = { styles.dark }
                                            //onclick="followLink('/search.jsp')"
                                            onClick = { handleBuy }
                                            //onClick = {() => handleCircelPayment()}
                                            onClick={() => handleAddToCart(productDetails) }
                                            //style={{ textShadow: "0px 1px 2px #1657F5", paddingTop: "20px", paddingBottom: "20px" }}
                                            style = {{
                                                  padding: "6px 12px"
                                                , backgroundColor: "rgba(0, 0, 255, 1)"
                                                //, background: "#0000FF"
                                                , borderRadius: "5px"
                                                , color: "#FFF"
                                                , fontFamily: "'Source Sans 3'"
                                                , fontWeight: "400"
                                                , fontSize: "20px"

                                            }}
                                            >Invest Now</button>
                                        <button
                                            type = 'button'
                                            //className = 'theme-primary large'
                                            className = 'theme-success large'
                                            onClick = {() => handleAddToCart(productDetails) }
                                            >Invest Now</button>
                                        */}
                                    </div>
                            )}

                            </>
                        )
                    }
                </div>

                {/* Checkout modal */}

                <div
                    //className = { styles.checkout }
                    className={headerStyles.loginModal}
                    style={{ display: open ? 'flex' : 'none' }}
                    onClick={() => {
                        setOpen(false);
                        setFractionQuantity(1);
                    }}>
                    <div
                        //className = { styles.checkoutInner }
                        className={headerStyles.loginModalContent}
                        //style = {{ position: "relative" }}
                        onClick={e => {
                            e.stopPropagation();
                        }}>
                        <div
                            className={headerStyles.loginModalClose}
                            onClick={() => closeModal()}>
                            <span>&#10005;</span>
                        </div>

                        <h2>Complete Checkout</h2>

                        <div
                            className={styles.closeBox}
                            onClick={() => {
                                setOpen(false);
                                setFractionQuantity(1);
                            }}>
                            X
                        </div>

                        <div className={styles.checkoutDetail}>
                            <div>
                                <p className={styles.boldText}>Asset Details</p>
                            </div>
                            <div>
                                {
                                    productDetails?.mediaType === 'audio'
                                    && (
                                        <div
                                            className={styles.playerwrapper}
                                            style={{
                                                position: 'relative',
                                                paddingTop: 0,
                                                width: 120
                                            }}>
                                            {/* <div> */}
                                            <img
                                                src={productDetails?.audioThumbnail}
                                                style={{
                                                    width: '120px',
                                                    height: '90px',
                                                    objectFit: 'cover'
                                                }}
                                                alt='thumbnail.png'
                                                />
                                            <audio
                                                controls
                                                controlsList='nodownload'
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    height: 25,
                                                    width: '100%'
                                                }}>
                                                <source
                                                    src={productDetails?.mediaPreviewUrl}
                                                    type='audio/mpeg'
                                                    />
                                            </audio>
                                            {/* </div> */}
                                        </div>
                                    )
                                }

                                {
                                    productDetails?.mediaType === 'video'
                                    && (
                                        <div
                                            className={styles.playerwrapper}
                                            //style={item?.ipfsAudioUrl ? { position: "relative", paddingTop: "0px" } : { position: "relative", paddingTop: "56%" }}
                                            style={{ position: 'relative', paddingTop: 0 }}>
                                            <ReactPlayer
                                                url={productDetails?.mediaPreviewUrl}
                                                width='150px'
                                                height='90px'
                                                controls={true}
                                                light={false}
                                                //style={{ position: "absolute", top: "0", left: "0" }}
                                                config={{
                                                    file: {
                                                        attributes: {
                                                            disablePictureInPicture: true,
                                                            controlsList: 'nodownload'
                                                        }
                                                    }
                                                }}
                                                />
                                        </div>
                                    )
                                }

                                {
                                    (
                                           productDetails?.mediaType === 'image'
                                        || productDetails?.mediaType === '3D Model'
                                    )
                                    && (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                            className={styles.playerwrapper}>
                                            <img
                                                src={
                                                      productDetails?.thumbnailUrl
                                                   || productDetails?.mediaPreviewUrl
                                                }
                                                style={{
                                                    width: '80px',
                                                    objectFit: 'cover'
                                                }}
                                                alt='thumbnail.png'
                                                />
                                        </div>
                                    )
                                }

                                {/*
                                <div className = { styles.checkoutPlayer }>
                                    <Lightbox
                                        productPreviews={productPreviews}
                                        productDetails={productDetails}
                                        width="100%"
                                        audioHeight="350px"
                                        />
                                </div>
                                */}

                                <span className={styles.boldText}>
                                      {'Name: ' + productDetails?.name}
                                </span>
                            </div>

                            {/********* Required edition *********/}
                            {
                                productDetails?.isFractional
                                && (
                                    <div style={{ width: '100%', textAlign: 'right' }}>
                                        <div className={styles.requiredEditionsWrapper}>
                                            <label
                                                htmlFor='requiredEditions'
                                                className={styles.requiredEditionsLabel}>
                                                No of fractions<span style={{ color: '#f00' }}>*</span>
                                            </label>
                                            <div>
                                                <input
                                                    type='number'
                                                    className={styles.requiredEditionInput}
                                                    placeholder='Enter fractions'
                                                    min={1}
                                                    id='requiredEditions'
                                                    value={fractionQuantity}
                                                    onChange={handleEditionChange}
                                                    onKeyDown={e => preventNonNumericalInput(e)}
                                                    />
                                                <span className={styles.totalEditionsLabel}>
                                                    / {productInfo?.availableFractions}
                                                </span>
                                            </div>
                                        </div>
                                        {
                                            errorFraction != ''
                                            && (
                                                <label style={{ color: '#f00' }}>{errorFraction}</label>
                                            )
                                        }
                                    </div>
                                )
                            }

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                <p className={styles.boldText}>Price</p>
                                <p>
                                    <span style={{ fontWeight: 'bold', marginRight: 5 }}>
                                        ${' '}
                                        {
                                            productDetails?.isFractional
                                            ? parseInt(fractionQuantity) > 0
                                                ? productInfo?.isResell
                                                    ? (productInfo?.resalePrice * parseInt(fractionQuantity)).toFixed(4)
                                                    : (productDetails?.regularPrice * parseInt(fractionQuantity))?.toFixed(4)
                                                : '0'
                                            : productInfo?.isResell
                                                ? productInfo?.resalePrice?.toFixed(4)
                                                : productDetails?.regularPrice?.toFixed(4)
                                        }
                                    </span>
                                    ({
                                        productDetails?.isFractional
                                        ? parseInt(fractionQuantity) > 0
                                            ? productInfo?.isResell
                                                ? (productInfo?.resalePrice * parseInt(fractionQuantity) * euroValue).toFixed(4)
                                                : (productDetails?.regularPrice * parseInt(fractionQuantity) * euroValue)?.toFixed(4)
                                            : '0'
                                        : productInfo?.isResell
                                            ? (productInfo?.resalePrice * euroValue).toFixed(4)
                                            : (productDetails?.regularPrice * euroValue)?.toFixed(4)
                                    }
                                    {' '}
                                    Matic)
                                </p>
                            </div>

                            {/*
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p>Royalty</p>
                                <p>
                                  <span style={{ fontWeight: "bold", marginRight: 5 }}>
                                    {(productDetails?.royaltyCommission)?.toFixed(2)} EUR
                                  </span>
                                  ({(productDetails?.royaltyCommission / euroValue).toFixed(5)} MATIC)
                                </p>
                            </div>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <p>Total</p>
                                <p>
                                  <span style={{ fontWeight: "bold", marginRight: 5 }}>
                                    {(productDetails?.royaltyCommission + productDetails?.salePrice * euroValue)?.toFixed(2)} EUR
                                  </span>
                                  ({(productDetails?.salePrice + productDetails?.royaltyCommission / euroValue).toFixed(5)} MATIC)
                                </p>
                            </div>
                            */}

                        </div>

                        <div
                            style={{ textAlign: 'center' }}
                            className={styles.checkoutTag}>
                            {/*
                            <button
                                //  className={cn("button-wallet", styles.button)}
                                className={cn('button')}
                                style={{ fontSize: '11px' }}
                                onClick={handleCheckout}
                                >
                                <span>CHECKOUT</span>
                            </button>
                            */}

                            <button
                                className={styles.buttonFullWidth}
                                onClick={() => handleCircelPayment()}
                                style={{ borderRadius: 5, marginLeft: 5, fontSize: '11px' }}>
                                <span>CHECKOUT WITH CARD</span>
                            </button>
                        </div>
                    </div>
                </div>
                {/* END checkout modal */}

                <Loader active={active} auction={isAuction} />
            </section>

            <section
                className = { "property-information" }
                style = {{ paddingTop: "0px" }}
                >
                <div className = { 'max-width' }>
                    <div className = { 'property-information' }>

                        <h2>Property Information</h2>

                        <p
                            style = {{
                                textAlign: "center"
                                , margin: "0px"
                                //, padding: "0px"
                                , padding: "20px 0px"
                            }}>
                            {/*
                            <img src='/images/properties/property1/property1-map.jpg' />
                            */}
                            <img
                                src={
                                      productDetails?.thumbnailUrl
                                   || productDetails?.mediaPreviewUrl
                                }
                                style = {{
                                      width: '100%'
                                    , maxWidth: '640px'
                                    //, objectFit: 'cover'
                                }}
                                //alt='thumbnail.png'
                                />
                        </p>

                        {/*
                        <p>
                            { productDetails?.shortDescription }
                        </p>
                        */}
                        <div
                            dangerouslySetInnerHTML = {{ __html: productDetails?.shortDescription }}
                            />

                        {/*
                          Files:
                            id, name, type, url, previewUrl, size, position, isThumbnail, defaultThumbnailUrl, isShortVideo, isIpfs, cid, assetId, createdAt, updatedAt
                        <p>
                            
                            Files: { productDetails?.files?.length }
                            <br/>
                            {
                                productDetails?.files?.length
                                && productDetails.files.map((el, i) => {
                                  return (
                                    <div>
                                        { el.previewUrl }
                                    </div>
                                )})
                            }
                        </p>
                        */}

                        {
                            productDetails?.files?.length > 0
                            && 
                            <div
                                className = { 'item-images' }
                                key = { fileIndex++ }
                                style = {{
                                    textAlign: "center"
                                    , margin: "0px"
                                    , padding: "20px 0px"
                                }}
                                >

                                <div
                                    className = { 'item-images-container' }
                                    style = {{
                                        //  display: "flex"
                                        //, flexWrap: "wrap"
                                        //, gap: "10px"
                                    }}
                                    >

                                    {
                                        productDetails?.files?.length > 0
                                        && productDetails.files.map((el, i) => {
                                          //i == 0 ? return null;
                                          //i == 0 && return null;
                                          if (i == 0) return null;
                                          return (
                                            <div
                                                className = { 'item-image' }
                                                key = { i }
                                                //style = {{ flexBasis: "calc(50% - 10px)" }}
                                                >
                                                <img
                                                    src = { el.previewUrl }
                                                    style = {{ width: '100%', height: '-webkit-fill-available' }}
                                                    />
                                            </div>
                                        )})
                                    }
                                </div>
                            </div>
                        }

                        {/*
                        <p>
                            { productDetails?.description }
                        </p>
                        */}
                        <div dangerouslySetInnerHTML = {{ __html: productDetails?.description }} />

                        {
                            productDetails?.strategy
                            &&
                              <>
                              <h4>Demographic Information</h4>
                              <p>
                                  { productDetails?.demographics }
                              </p>
                              <hr style = {{ backgroundColor: "#CED4DA", lineHeight: "1px", margin: "20px 0px 20px 0px" }} />
                              </>
                        }

                        {/*
                            productDetails?.status != 'past-deal'
                            && (
                                <p style = {{ textAlign: "center" }}>
                                    <button
                                        type = 'button'
                                        className = 'theme-success large'
                                        onClick = {() => handleAddToCart(productDetails) }
                                        >Invest Now</button>
                                </p>
                            )
                        */}

                        {
                            productDetails?.amenities
                            //productDetails?.amenities && productDetails?.amenities.length > 0
                            &&
                                <>
                                <h3>Amenities</h3>
                                <div dangerouslySetInnerHTML = {{ __html: productDetails?.amenities }} />
                                {/*<hr style = {{ backgroundColor: "#CED4DA", lineHeight: "1px", margin: "0px 0px 0px 0px" }} />*/}
                                </>
                        }

                        {
                            productDetails?.strategy
                            &&
                                <>
                                <h3>Strategy</h3>
                                <div dangerouslySetInnerHTML={{ __html: productDetails?.strategy }} />
                                </>
                        }

                        {/*
                        <h3>Other Financials</h3>

                        <div
                            className = { "table financials-table" }
                            style = {{
                                width: "100%"
                            }}
                            >
                            <div className = { "row" }>
                                <div className = { "cell" }>
                                    Original Purchase Date:
                                </div>
                                <div className = { "cell" }>
                                    { 
                                        productDetails?.purchaseDate
                                        &&
                                            new Date(
                                                Date.parse(productDetails.purchaseDate)
                                            )
                                            .toLocaleDateString(
                                                "en-US"
                                                //, { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' }
                                                , { year: 'numeric', month: 'long', day: 'numeric' }
                                            )
                                    }
                                </div>
                            </div>
                            <div className = { "row" }>
                                <div className = { "cell" }>
                                    Original Purchase Price:
                                </div>
                                <div className = { "cell" }>
                                    { productDetails?.purchasePrice }
                                </div>
                            </div>
                            {
                                productDetails?.downPayment
                                && <div className = { "row" }>
                                    <div className = { "cell" }>
                                        Initial Down Payment:
                                    </div>
                                    <div className = { "cell" }>
                                        { productDetails?.downPayment }
                                    </div>
                                </div>
                            }

                            {
                                productDetails?.loanAmount
                                && <div className = { "row" }>
                                    <div className = { "cell" }>
                                        Original Loan Amount:
                                    </div>
                                    <div className = { "cell" }>
                                        { productDetails?.loanAmount }
                                    </div>
                                </div>
                            }

                            {
                                productDetails?.interestRate
                                && <div className = { "row" }>
                                    <div className = { "cell" }>
                                        Interest Rate
                                    </div>
                                    <div className = { "cell" }>
                                        { productDetails?.interestRate }
                                    </div>
                                </div>
                            }

                            {
                                productDetails?.loanTerm
                                && <div className = { "row" }>
                                    <div className = { "cell" }>
                                        Loan Term:
                                    </div>
                                    <div className = { "cell" }>
                                        { productDetails?.loanTerm }
                                    </div>
                                </div>
                            }
                        </div>
                        */}

                        {
                            productDetails?.status != 'past-deal'
                            && (
                                <>

                                {/*<hr style = {{ backgroundColor: "#CED4DA", lineHeight: "1px", margin: "25px 0" }} />*/}

                                <p style = {{ textAlign: "center", margin: "0px", padding: "0px" }}>
                                    {/*
                                    <button
                                        type="button"
                                        className = { cn("theme-primary")}
                                        //className = { styles.dark }
                                        //onclick="followLink('/search.jsp')"
                                        //style={{ textShadow: "0px 1px 2px #1657F5", paddingTop: "20px", paddingBottom: "20px" }}
                                        style={{
                                              padding: "6px 12px"
                                            , backgroundColor: "rgba(0, 0, 255, 1)"
                                            //, background: "#0000FF"
                                            , borderRadius: "5px"
                                            , color: "#FFF"
                                            , fontFamily: "'Source Sans 3'"
                                            , fontWeight: "400"
                                            , fontSize: "20px"

                                        }}
                                        >Invest Now</button>
                                    */}
                                    <button
                                        type = 'button'
                                        className = 'theme-success large text-shadow'
                                        onClick = {() => props.handleAddToCart(productDetails) }
                                        >Invest Now</button>
                                </p>
                                </>
                            )
                        }

                    </div>
                </div>
            </section>
        </main>
        </>
    );
};

export default Item;
